@import '../../../sass/imports';

.app-home-screen {
    width: 100vw;
    height: 100vh;

    .app-home-screen-outer-container {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        left: 0;
        overflow: hidden;
        background: #fff;
    }
    .app-home-screen-inner-container {
        width: 100vw;
        height: 100vh;
        position: relative;
        margin: 0;
        padding: 0;
    }

    .app-home-screen-flex-container {
        display: flex;
        flex-direction: column;
        height: 100vh;
    }

    .app-home-screen-background-iamge {
        margin-top: 20%;
        flex: 1;
        background-image: url('https://static.chewba.info/images/mobile-login-image.jpg');
        background-size: cover;
        background-position: center;
        position: relative;
    }

    header {
        position: absolute;
        z-index: 100;
        top: 0;
        left: 0;
        width: 100%;
        text-align: center;
        background: linear-gradient(
            180deg,
            #f1f2f5 31.99%,
            rgba(241, 242, 245, 0.927083) 59.03%,
            rgba(241, 242, 245, 0) 100%
        );
        min-height: 325px;

        @supports (padding-top: constant(safe-area-inset-top)) {
            padding-top: constant(safe-area-inset-top);
        }
        @supports (padding-top: env(safe-area-inset-top)) {
            padding-top: env(safe-area-inset-top);
        }

        img {
            width: 200px;
        }

        h2 {
            margin: 20px;
            font-weight: 900;
            font-size: 24px;
            line-height: 38px;
            color: $dark-slate-gray;
        }

        p {
            text-transform: uppercase;
            font-size: 18px;
            color: $dark-slate-gray;
            margin-bottom: 15px;
        }
    }

    footer {
        left: 0;
        right: 0;
        width: 100%;
        padding: 10px 0;
        text-align: center;
        background-color: #f1f2f5;

        @supports (padding-bottom: constant(safe-area-inset-bottom)) {
            padding-bottom: constant(safe-area-inset-bottom);
        }
        @supports (padding-bottom: env(safe-area-inset-bottom)) {
            padding-bottom: env(safe-area-inset-bottom);
        }
    }

    .sign-in-btn {
        @include flat-button($orange4);
        font-size: 16px;
        padding: 15px 50px;
        font-weight: 800;
        letter-spacing: 1.5px;
        width: 90%;
        margin: 10px;
    }

    .sign-up-btn {
        @include flat-button($text-gray);
        font-size: 16px;
        padding: 15px 50px;
        font-weight: 800;
        letter-spacing: 1.5px;
        width: 90%;
        margin: 10px;
    }
}
